define("ember-bootstrap-changeset-validations/components/bs-form", ["exports", "ember-bootstrap/components/bs-form"], function (_exports, _bsForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _bsForm.default.extend({
    '__ember-bootstrap_subclass': true,
    hasValidator: Ember.computed.notEmpty('model.validate'),
    validate: function validate(model) {
      var m = model;
      (false && !(m && typeof m.validate === 'function') && Ember.assert('Model must be a Changeset instance', m && typeof m.validate === 'function'));
      return new Ember.RSVP.Promise(function (resolve, reject) {
        m.validate().then(function () {
          model.get('isValid') ? resolve() : reject();
        }, reject);
      });
    }
  });

  _exports.default = _default;
});