define("ember-table/-private/utils/computed", ["exports", "ember-table/-private/utils/observer"], function (_exports, _observer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dynamicAlias = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var PROPERTIES = new WeakMap();

  function findOrCreatePropertyInstance(propertyClass, context, key) {
    var _arguments = arguments;

    if (!PROPERTIES.has(context)) {
      PROPERTIES.set(context, {});
    }

    var propertiesForContext = PROPERTIES.get(context);
    var property = propertiesForContext[key];

    if (property) {
      return property;
    }

    property = propertyClass.create({
      _key: key,
      _context: context
    });
    var originalWillDestroy = context.willDestroy; // HACK: wrap around the original willDestroy to destroy our property as well

    context.willDestroy = function () {
      property.destroy();
      originalWillDestroy.apply(context, _arguments);
    };

    propertiesForContext[key] = property;
    return property;
  }

  var ClassBasedComputedProperty = Ember.Object.extend({
    _context: null,
    _key: null,
    _computedFunction: null,
    _dependencies: null,
    init: function init() {
      this._super.apply(this, arguments);

      this._redefineProperty();
    },
    // eslint-disable-next-line
    _contentDidChange: (0, _observer.observer)('_content', function () {
      if (!this._isUpdating && !this._context.isDestroyed && !this._context.isDestroying) {
        this._context.notifyPropertyChange(this._key);
      }
    }),
    _redefineProperty: function _redefineProperty() {
      var _this = this;

      var dependencies = this.get('_dependencies');
      var isDynamicList = this.get('_isDynamicList');

      var computed = this._computedFunction.apply(this, _toConsumableArray(dependencies.map(function (d, i) {
        return isDynamicList[i] ? _this.get("_context.".concat(d)) : "_context.".concat(d);
      })));

      Ember.defineProperty(this, '_content', computed);
    },
    _get: function _get() {
      return this.get('_content');
    },
    _set: function _set(key, value) {
      this._isUpdating = true;
      this.set('_content', value);
      this._isUpdating = false;
      return this._get();
    }
  });

  function classComputedProperty(isDynamicList, computedFunction) {
    return function () {
      var _Ember;

      for (var _len = arguments.length, dependencies = new Array(_len), _key = 0; _key < _len; _key++) {
        dependencies[_key] = arguments[_key];
      }

      var extension = {
        _computedFunction: computedFunction,
        _isDynamicList: isDynamicList,
        _dependencies: dependencies
      };
      dependencies.forEach(function (dep, index) {
        if (isDynamicList[index] === true) {
          // eslint-disable-next-line
          extension["".concat(dep, "DidChange")] = (0, _observer.observer)("_context.".concat(dep), function () {
            this._redefineProperty();
          });
        }
      });
      var klass = ClassBasedComputedProperty.extend(extension);
      return (_Ember = Ember).computed.apply(_Ember, dependencies.concat([{
        get: function get(key) {
          var property = findOrCreatePropertyInstance(klass, this, key);
          return property._get();
        },
        set: function set(key, value) {
          var property = findOrCreatePropertyInstance(klass, this, key);
          return property._set(key, value);
        }
      }]));
    };
  }

  var dynamicAlias = classComputedProperty([false, true], function () {
    for (var _len2 = arguments.length, segments = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      segments[_key2] = arguments[_key2];
    }

    if (segments.every(function (s) {
      return typeof s === 'string';
    })) {
      return Ember.computed.alias(segments.join('.'));
    } else {
      return null;
    }
  });
  _exports.dynamicAlias = dynamicAlias;
});